import React, { useState, useEffect } from "react"
import { mintData, upcomingData, projectData, teamData } from "@util/hvoriginsData"
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui"
import { connect } from "react-redux"
import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "@util/candy-machine"
import { icons } from "@components/layout/Layout/menu"
import "./HomePage.scss"

const Homepage = ({
  candyMachineId,
  config,
  connection,
  startDate,
  treasury,
  txTimeout,
  wallet,
  connected
}) => {
  const [itemsRemaining, setItemsRemaining] = useState(-1)
  const [isSoldOut, setIsSoldOut] = useState(true) // true when items remaining is zero
  const [mintStatus, setMintStaus] = useState(0) // 0: mint, 1:pending, 2:success
  const [candyMachine, setCandyMachine] = useState()

  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const connectHandler = () => {
    localStorage.setItem('hv_connect', true);
  }

  const refreshCandyMachineState = () => {
    ; (async () => {
      if (!wallet) return

      const { candyMachine, itemsRemaining } = await getCandyMachineState(
        wallet,
        candyMachineId,
        connection
      )

      //setItemsRemaining(itemsRemaining)
      setItemsRemaining(0)
      //setIsSoldOut(itemsRemaining === 0)
      setCandyMachine(candyMachine)
    })()
  }
/*
  const refreshItemsRemainingState = () => {
    ; (async () => {
      if (!wallet) return

      const { itemsRemaining } = await getCandyMachineState(
        wallet,
        candyMachineId,
        connection
      )

      setItemsRemaining(itemsRemaining)
      setIsSoldOut(itemsRemaining === 0)
    })()
  }

  useEffect(() => {
    if (!wallet) return

    let myInterval = setInterval(() => {
      refreshItemsRemainingState()
    }, 15000)
    return () => {
      clearInterval(myInterval)
    }
  })
*/
  useEffect(refreshCandyMachineState, [
    wallet,
    candyMachineId,
    connection,
  ])

  const handleMint = async () => {
    if (mintStatus !== 0) return

    /*
            if (!JSONData.wallets.find(item => item.address === wallet.publicKey.toString())) {
              setAlertState({
                open: true,
                message: "You must be on the whitelist to mint during Presale.",
                severity: "error",
              });
        
              return;
            }
    const count = localStorage.getItem(wallet.publicKey.toString()) | 0
    if (count >= 15) {
      setAlertState({
        open: true,
        message: "You are only allowed 5 mints.",
        severity: "error",
      })

      return
    }
    */

    try {
      setMintStaus(1)
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          config,
          wallet.publicKey,
          treasury
        )

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          txTimeout,
          connection,
          "singleGossip",
          false
        )

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          })

          // localStorage.setItem(wallet.publicKey.toString(), count + 1)

          setMintStaus(2)
          let timer = setTimeout(() => setMintStaus(0), 2000)

          return () => clearTimeout(timer)
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          })
          setMintStaus(0)
        }
      }
    } catch (error) {
      // TODO: blech:
      let message = error.message || "Minting failed! Please try again!"
      if (!error.message) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`
          setIsSoldOut(true)
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`
          //        } else if (error.message === "You must be on the whitelist to mint during Presale.") {
          //          message = error.message;
        } else if (error.message === "You are only allowed 10 mints.") {
          message = error.message
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      })
      setMintStaus(0)
    } finally {
      refreshCandyMachineState()
    }
  }

  const RareItems = () => {
    const items = []
    for (let i = 1; i < 16; i++) {
      items.push(
        <div className="grid-item" key={i}>
          <img src={`/home_page/rare_${i}.png`} className="w-full" />
        </div>
      )
    }

    return items
  }

  const ConMintButton = props => {
    return (
      <>
        {props.wallet ? (
          <div 
            className={`btn btn-mint btn-image font-montserrat italic h-18 w-2/3 flex items-center justify-center text-3xl uppercase ${props.status === 0 ? "cursor-pointer" : ""}`}
          >
            {
              props.isSoldOut 
              ? "SOLD OUT!" 
              : props.status === 0
              ? "Mint"
              : props.status === 1
              ? "Pending ..."
              : "Success"
            }
          </div>
        ) : (
          <WalletDialogButton className="btn btn-mint btn-image font-montserrat italic h-18 w-2/3 flex items-center justify-center text-3xl uppercase" onClick={connectHandler}>
            Connect  
          </WalletDialogButton>
        )}
      </>
    )
  }

  const HomeBox = () => {
    return (
      <div className="grid grid-cols-1 xl:grid-cols-3">
        <div className="grid-item text-center bg-image bg-img-blue px-4 py-4 order-2 xl:order-1">
          <div className="w-max-500 mx-auto">
            <div className="flex justify-center pt-3">
              <img src="/home_page/logo_extreme.png" alt="logo extreme" />
            </div>
            <div className="mt-4">
              <p className="text-4xl lg:text-5xl font-staatliches">
                Play-to-earn RPG
              </p>
              <p className="font-montserrat italic font-extrabold mt-3 lg:text-lg">
                Breaking news! AAA Game Studio <u><a href="https://game-ace.com/" target="_blank">GAME-ACE</a></u> has begun development on our turn-based, strategy RPG. Blockchain integration will be handled by <u><a href="https://masterbrews.com" target="_blank">MASTERBREWS</a></u>.
              </p>
              <p className="font-montserrat italic font-extrabold mt-3 lg:text-lg">
                Initial game modes include: Campaign/Story, Recruit, Train, Clone Lab and an expansive in-game marketplace. Upcoming features introduce PVP, a Rental Program, Teams, Headquarters and much, much more!. Isn't it time P2E was actually fun ?
              </p>
              <div className="mt-12">
                <div className="flex items-center justify-center text-4xl lg:text-5xl font-staatliches gap-2">
                  <img src="/home_page/text_safe.png" alt="text safe" />
                </div><p>&nbsp;</p>
                <p className="text-4xl lg:text-5xl font-staatliches">
                  15% CHANCE AT RARE MINT!
                </p>
                <p className="font-montserrat italic font-extrabold mt-3 lg:text-lg">
                  All remaining mints are human and have a 1-in-7 chance of being drafted by S.A.F.E. You will be able to recognize them both by their "corporate logo" in the top-left corner of your (final stage) art and by a new "Organization" trait in the metadata. The trait will be present immediately upon minting.
                </p>
                <p className="font-montserrat italic font-extrabold mt-3 lg:text-lg">
                  S.A.F.E. draftees will be able to volunteer for bio-enhancement in our MUTANTS expansion, granting a FREE MINT of an exclusive character class.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-item text-center bg-image bg-img-yellow px-4 py-4 order-1 xl:order-2">
          <div className="w-max-500 mx-auto">
            <div className="flex justify-center">
              <img src="/home_page/logo_hv.png" alt="logo hv" />
            </div>
            <div className="mt-6">
              <div className="flex items-center justify-center gap-2">
                <img src="/home_page/icon_arrow.png" alt="icon arrow" className="w-10" />
                <p className="text-5xl lg:text-6xl font-staatliches">
                  MINTING NOW
                </p>
                <img src="/home_page/icon_arrow.png" alt="icon arrow" className="w-10 transform rotate-180" />
              </div>
              <div className="text-3xl lg:text-4xl font-staatliches gap-2 mt-12">
                <p>REMAINING: 0/3750</p>
                <p className="mt-2">PRICE: 1.5 SOL</p>
                <p className="mt-2">EVOLVES: EVERY 48 HOURS</p>
              </div>
              <div className="flex flex-col justify-center items-center mt-5">
                <ConMintButton
                  wallet={wallet}
                  action={handleMint}
                  status={mintStatus}
                  isSoldOut={isSoldOut}
                />
                <img src="/home_page/icon_solo.png" alt="icon solo" className="w-12 h-12" />
              </div>
              <div className="grid grid-cols-3 mt-24 sm:mt-28 gap-2">
                {mintData.map((item, index) => {
                  return (
                    <div className="grid-item bg-gradient relative pt-16 pb-4 px-2" key={index}>
                      <img src={item.img} alt="img hv" className={`${item.width} absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2`} />
                      <p className="font-montserrat-bold pb-2 text-xs sm:text-base">
                        {item.content}
                      </p>
                      <a href={item.link} className="font-montserrat text-xs sm:text-sm uppercase bg-blue-500 py-1 px-2 mt-2">
                        {item.label}
                      </a>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="grid-item text-center bg-image bg-img-red px-4 py-4 order-3">
          <div className="w-max-500 mx-auto">
            <p className="font-montserrat-bold italic font-extrabold text-4xl lg:text-5xl pt-3">
              EXCLUSIVE ULTRARARES
            </p>
            <div className="grid grid-cols-3 gap-x-2 gap-y-3 px-10 mt-6">
              <RareItems />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const MilestonesBox = () => {
    return (
      <div className="bg-image milestones-section text-white">
        <div className="container mx-auto py-12">
          <p className="font-montserrat-bold italic font-extrabold text-4xl text-center mb-24">
            UPCOMING MILESTONES:
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {upcomingData.map((item, index) => {
              return (
                <div className="miles-item bg-gradient relative pt-18 pb-4 px-4 mb-20 text-center" key={index}>
                  <img src={item.img} alt="img hv" className={`${item.width} absolute ${index === 0 ? "-top-2" : "top-0"} left-1/2 transform -translate-x-1/2 -translate-y-1/2`} />
                  <a href={item.link} className="font-montserrat text-sm uppercase bg-blue-500 py-1 px-2 mt-2">
                    {item.status}
                  </a>
                  <p className="font-montserrat-bold pt-2">
                    {item.content}
                  </p>
                </div>
              )
            })}
          </div>
          <p className="font-montserrat-bold italic font-extrabold text-4xl text-center mb-24">
            PROJECT MILESTONES:
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            {projectData.map((item, index) => {
              return (
                <div className="miles-item bg-gradient relative pt-18 pb-10 px-4 mb-21 text-center" key={index}>
                  <img src={item.img} alt="img hv" className={`${item.width} absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2`} />
                  <a href={item.link} className="font-montserrat text-sm uppercase bg-blue-500 py-1 px-2 mt-2">
                    {item.status}
                  </a>
                  <p className="font-montserrat-bold pt-2">
                    {item.content}
                  </p>
                  {item.isChecked && (
                    <img src="/home_page/icon_checked.png" alt="icon checked" className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2" />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const TeamBox = () => {
    return (
      <div className="bg-image teams-section">
        <div className="flex max-w-4xl xl:max-w-5xl flex-col font-montserrat-bold m-auto px-10 small:px-20 sm:px-10">
          <div className="text-4xl md:text-5xl team-title text-center m-auto mt-12 xl:mt-14 mb-24 small:mb-32 sm:mb-24 md:mb-32 lg:mb-24 xl:mb-32 pb-3">OUR TEAM</div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 lg:grid-cols-3 pb-5 pt-10 m-auto z-0 ">
            {teamData.map((dt, idx) => (
              <div className="grid-item text-center bg-white relative pt-24 pb-16 px-2 xl:px-4 mb-36" key={idx}>
                <div className="team-item-img absolute -top-6 lg:-top-5 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 w-2/3">
                  <img src={dt.img} className="w-full" />
                </div>
                <p className="text-black w-full text-2xl xl:text-2xl mt-3 small:mt-6 sm:mt-3 md:mt-6 xl:mt-3">
                  {dt.name}
                </p>
                <p className="text-black-light mb-3 xl:text-lg">
                  {dt.role}
                </p>
                <p className="text-black text-sm font-montserrat font-extrabold">
                  {dt.content}
                </p>
                <div className="flex gap-5 justify-center mt-3 xl:mt-4 absolute bottom-6 left-1/2 transform -translate-x-1/2">
                  <a href={dt.twitter} target="_blank" rel="noreferrer">
                    <img src="/icon/icon_twitter.png" alt="icon twitter" className="w-8"></img>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="w-10 divider absolute"></div>
        </div>
      </div>
    )
  }

  const CommunityBox = () => {
    return (
      <div className="bg-image community-section pt-12 pb-12 lg:pb-20">
        <div className="grid grid-cols-1 lg:grid-cols-5 container font-montserrat-bold m-auto px-10 text-white">
          <div className="grid-item lg:col-span-3 lg:w-4/5 2xl:w-3/4">
            <p className="text-4xl lg:text-5xl text-center lg:text-left">
              COMMUNITY
            </p>
            <p className="font-montserrat text-lg lg:text-xl mt-4 mb-12">
              Forget content; community is the true king. Without our loyal, passionate holders; we would have nothing. Thank you to everyone who has got us to where we are today and who will follow us on the journey into the (very bright) future.
            </p>
            <p className="font-montserrat text-lg lg:text-xl mt-4 mb-12">
              New and seeking somewhere to call home? Our organic community is much like a family, heck even our villains are warm and welcoming! We play together, we snipe together, we evolve together. Our community helped create our first comic book, featuring their characters and stories 
              with art by our legendary team. We invite you to take a look and decide for yourself.
            </p>
            <a className="bg-black border-4 border-white rounded-2xl px-4 py-2 text-2xl lg:text-3xl italic" href={icons[1].link} target="_blank" rel="noreferrer">
              JOIN OUR DISCORD
            </a>
            <div className="mt-12 xl:mt-16">
              <div className="flex items-center">
                <img src="/home_page/icon_weekly.png" alt="icon weekly" className="w-8 xl:w-9"/>
                <p className="font-staatliches text-2xl xl:text-3xl uppercase underline ml-3">
                  Weekly Contests And Games
                </p>
              </div>
              <div className="flex items-center my-3 xl:my-4">
                <img src="/home_page/icon_handshake.png" alt="icon weekly" className="w-8 xl:w-9"/>
                <p className="font-staatliches text-2xl xl:text-3xl uppercase underline ml-3">
                  Exclusive Sneak Peaks, Community Creations and Partner Collaborations
                </p>
              </div>
              <div className="flex items-center">
                <img src="/home_page/icon_giveaway.png" alt="icon weekly" className="w-8 xl:w-9"/>
                <p className="font-staatliches text-2xl xl:text-3xl uppercase underline ml-3">
                  Giveaways And Prizes
                </p>
              </div>
            </div>
          </div>
          <div className="grid-item lg:col-span-2 flex justify-center items-center mt-12 lg:mt-0">
            <img src="/home_page/community.png" alt="img community" className="md:w-3/4 lg:w-full 2xl:w-3/4"/>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="home-container text-white">
      <HomeBox />
      <MilestonesBox />
      <TeamBox />
      <CommunityBox />
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  )
}

const stateProps = (state) => ({
  wallet: state.wallet,
  connected: state.connected
});

export default connect(stateProps, null)(Homepage);
