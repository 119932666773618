import React from "react"
import * as anchor from "@project-serum/anchor"
import Layout from "@components/layout/Layout/Layout"
import Homepage from "@components/partials/HomePage"

const hero_treasury = new anchor.web3.PublicKey(
  process.env.GATSBY_APP_HERO_TREASURY_ADDRESS
);

const hero_config = new anchor.web3.PublicKey(
  process.env.GATSBY_APP_HERO_CANDY_MACHINE_CONFIG
);

const hero_candyMachineId = new anchor.web3.PublicKey(
  process.env.GATSBY_APP_HERO_CANDY_MACHINE_ID
);

const rpcHost = process.env.GATSBY_APP_SOLANA_RPC_HOST;
const connection = new anchor.web3.Connection(rpcHost);

const hero_startDateSeed = parseInt(process.env.GATSBY_APP_HERO_CANDY_START_DATE, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const Hvorigins = () => (
  <Layout
    title="Mark McKenna's Heroes & Villains NFT"
    contentClassName="homePageContent px-0"
    type="mint"
  >
    <Homepage
      candyMachineId={hero_candyMachineId}
      config={hero_config}
      connection={connection}
      startDate={hero_startDateSeed}
      treasury={hero_treasury}
      txTimeout={txTimeout}
    />
  </Layout>
)
export default Hvorigins
